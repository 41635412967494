<template>

  <div id="app"  style="position: relative;">
    <div id="radiosnav" v-show="!scrolled" class="nav_menu w" >
      <div @click="page(1)" class="cursor" style="display: flex;align-items: center;    margin-left: 26px;
    margin-right: -37px;">
        <img
            class="label_1 "
            referrerpolicy="no-referrer"
            src="./assets/img/wowpic.png"
        />
        <span class="text_1 footerwowtext">哇呜说</span>
      </div>
      <div style="margin-right: -30px;" class="menu_item sy"
           :class="{ 'activets': currentPage === 1, 'clicked': clickedItems[1] }" @click="page(1,$event)">
        <div class="home" style="width: 38px;">首页</div>
        <div class="web_home" style="width: 38px;">首页</div>
      </div>

      <div class="menu_item" :class="{ 'activenav': currentPage === 2 , 'rotate': isRotated  }">
        <div class="home">产品介绍</div>
        <div class="web_home" @click.stop="page(2,$event)">产品介绍</div>
      </div>

      <div class="menu_item" :class="{ 'activenav': currentPage === 3, 'clicked': clickedItems[3] }"
           @click="page(3,$event)" @mouseover="navanimate(2)" @animationend="animationEnd(2)">
        <div class="home">相关资讯</div>
        <div class="web_home">相关资讯</div>
      </div>

      <div style="margin-right: 30px;" class="menu_item"
           :class="{ 'activenav': currentPage === 4, 'clicked': clickedItems[4] }" @click="page(4,$event)"
           @mouseover="navanimate(3)" @animationend="animationEnd(3)">
        <div class="home">关于我们</div>
        <div class="web_home">关于我们</div>
      </div>

    </div>
    <div v-show="scrolled" class="nav_menu info" style="width: 100%;position: fixed;z-index: 9999">
      <div @click="page(1)" class="cursor" style="display: flex;align-items: center;    margin-left: 26px;
    margin-right: -37px;margin-left: 84px">
        <img
            class="label_1 "
            referrerpolicy="no-referrer"
            src="./assets/img/wowpic.png"
        />
        <span class="text_1 footerwowtext">哇呜说</span>
      </div>
      <div style="margin-right: -30px;margin-left: 142px" class="menu_item sy" :class="{ 'activets': currentPage === 1, 'clicked': clickedItems[1] }" @click="page(1,$event,3)">
        <div class="home" style="width: 38px;">首页</div>
        <div class="web_home" style="width: 38px;">首页</div>
      </div>

      <div style="margin-left: 132px" id="xx"  class="menu_item"  :class="{ 'activenav': currentPage === 2 , 'rotate': isRotated  }" >
        <div class="home">产品介绍</div>
        <div class="web_home"  @click.stop="page(2,$event,3)">产品介绍</div>
      </div>

      <div style="margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 3, 'clicked': clickedItems[3] }" @click="page(3,$event,3)" @mouseover="" @animationend="">
        <div class="home">相关资讯</div>
        <div class="web_home">相关资讯</div>
      </div>

      <div style="margin-right: 30px;margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 4, 'clicked': clickedItems[4] }" @click="page(4,$event,3)" @mouseover="" @animationend="">
        <div class="home">关于我们</div>
        <div class="web_home">关于我们</div>
      </div>
    </div>
    <router-view ::basicinfo="basicinfo" @update-current-page="handleUpdateCurrentPage" @@update-current-navstatus="upnavstatus"></router-view> <!-- 这里渲染匹配到的组件 -->
    <div class="footer" style="">
      <div class="footercontainer" style="">
        <div class="footerwow" style="">
          <img
              style="width: 46px;height: 49px;"
              referrerpolicy="no-referrer"
              src="./assets/img/wowpic.png"
          />
          <span class="footerwowtext" style="">
            哇呜说
          </span>
        </div>
<!--        androidQrcode-->
<!--        :-->
<!--        "https://wish2024-dev.oss-cn-beijing.aliyuncs.com/img/20240703/1719988967909.png"-->
        <p class="telandaddress" style="margin-top: 42px">
          联系电话：{{basicinfo.contactPhone}}
        </p>
        <p class="telandaddress" style="margin-top: 12px">
          联系邮箱：{{basicinfo.contactEmail}}
        </p>
        <p class="telandaddress" style="margin-top: 12px">
          联系地址：{{basicinfo.companyAddress}}
        </p>
        <hr style="margin-top: 47px;color: #D1D1D1;border: none;border-top: 1px solid #D1D1D1;">
        <div style="width: 100%;text-align: center; margin-top: 17px;">
          <span @click="icp()" class="icp">
      {{basicinfo.icpNumber}}
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {getInfo} from "@/api/user";
export default {
  name: 'App',
  components: {},
  data() {
    return {
      currentPage: 1,
      clickedItems: {},
      isRotated: false,
      scrolled: false,
      basicinfo:''
    }
  },
  mounted() {
    getInfo().then(res => {
      this.basicinfo=res.data
    })
    window.addEventListener('scroll', this.handleScroll);
    // 调用updateWidth方法以初始化组件宽度
    this.updateWidth();
    // 添加窗口resize事件监听器，当窗口大小改变时调用updateWidth方法更新组件宽度
    window.addEventListener('resize', this.updateWidth);
    window.addEventListener("scroll", this.scrolling);
    const currentPath = window.location.hash.substring(1).split('?')[0];
    console.log('Current path:', currentPath);
    if (currentPath === '/help/video'){
      console.log('Setting scrolled to true for video page');
      this.scrolled = true;
    }
  },

  beforeDestroy() {
    // 移除窗口resize事件监听器，防止内存泄漏
    window.removeEventListener('resize', this.updateWidth);
  },
  methods: {
    upnavstatus(value){
      this.scrolled=value
    },
    scrolling(targetPage) {
      // 滚动条距文档顶部的距离
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        // const pathsToCheck = [
        //   '/information/infodetails',
        //   '/introduction/hard/buy',
        //   '/introduction/app',
        //   '/introduction/hard'
        // ];
        // if (pathsToCheck.includes(window.location.pathname)) {
        //   console.log(window.location.pathname);
        //   console.log('sb')
        //   this.scrolled=false
        // }
        console.log("header  你已经到底部了");

      }
      if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
      } else {

        this.scrolled=true
        // console.log("header  滚动条向下滚动了！");
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        const pathsToCheck = [
          '/information/infodetails',
          '/introduction/hard/buy',
          '/introduction/app',
          '/introduction/hard',
          '/help/video'
        ];
        const currentPath = window.location.hash.substring(1).split('?')[0];
        if (pathsToCheck.includes(currentPath)) {
          this.scrolled = true;
          console.log('Path match found:', currentPath);
        } else {
          console.log('No path match for:', currentPath);
          this.scrolled = false;
        }
        console.log("header 到了最顶部")
      }
    },
    handleUpdateCurrentPage(value) {
      this.currentPage = value;
    },
    
    updateWidth() {
      // 判断是否为移动设备
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (isMobile) {
        // 移动设备下的操作写在这里
      } else {
        // 非移动设备下，计算页面缩放比例以适应不同屏幕宽度
        const width = window.innerWidth || document.documentElement.clientWidth;
        var scale = width / 1920;
        document.documentElement.style.zoom = scale;
        // console.log(width); // 输出页面宽度，调试用
      }
    },
    icp() {
      window.open('https://beian.miit.gov.cn/', '_blank');
    },
    page(v, event,x) {
      if (x==3){
        this.scrolled=false
      }
      if (!this.clickedItems[v]) {
        this.$nextTick(() => {

        });
      }
      console.log(event);
      event.stopPropagation();
      // 更新clickedItems对象
      this.clickedItems = {};
      this.clickedItems[v] = true;
      // 设置当前页码
      this.currentPage = v;
      // 定义页面路由映射，用于将页码转换为对应的路由路径
      const routes = {
        1: '/',
        2: '/introduction',
        3: '/information',
        4: '/about'
      };

      // 根据页码跳转到对应的页面
      this.$router.push(routes[v]);
    },
  },
}
</script>

<style>
@import '/src/assets/font/font.css';

.web_home::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  width: 0;
  height: 100%;
  border-bottom: 4px solid rgb(248, 174, 1);
  transition: width 0.3s ease;
}

.web_home:hover::before {

  width: 100%;
}

.web_home:first-of-type {
  width: 50% !important;
}

.web_home:first-of-type::before {
  left: 13px;
  width: 50% !important;
}

.nav_menu {

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  overflow: hidden;
  height: 49px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 44px;
  width: 1200px;
  margin-left: 360px;
  position: absolute;
  top: 50px;
  z-index: 999;
  padding-top: 19px;
  padding-bottom: 20px;
}

.menu_item {
  margin-left: 19px;
  //border: 1px solid red;
  width: 60px;
  position: relative;
  height: 100%;
  line-height: 50px;
  text-align: center;
  transform-style: preserve-3d;
  transition: all 0.25s;
  transform-origin: center;
  /* margin: 0 5px; */
}

.menu_item:hover {

  cursor: pointer;
  transform: rotateX(90deg)
}

.home:hover {

}


.home, .web_home {
  position: absolute;
  top: 0;
  left: 0;
  width: 66px;
  height: 100%;
  perspective: 300px;
}

.home {
  height: 88px;
  z-index: 11;
  transform: translateZ(25px);
}

.web_home {
  margin-top: 20px;
  height: 86px;
  line-height: 88px;
  transform: translateY(25px) rotateX(-90deg);
}


/*base code*/
/* 1 */
.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.5s;
}


@-webkit-keyframes slideOutUpnav {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
  100% {

    visibility: unset !important;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0)
  }
}

@keyframes slideOutUpnav {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
  100% {
    visibility: unset !important;
    -webkit-transform: translate3d(0, -200%, 0);
    -ms-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -380%, 0)
  }
}

.slideOutUpnav {
  -webkit-animation-name: slideOutUpnav;
  animation-name: slideOutUpnav
}

.animateddown {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.animateddown.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animateddown.hinge {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.5s;
}

@-webkit-keyframes slideOutdownnav {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
  100% {

    visibility: unset !important;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0)
  }
}

@keyframes slideOutdownnav {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
  100% {
    visibility: unset !important;
    -webkit-transform: translate3d(0, -200%, 0);
    -ms-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, 80%, 0)
  }
}

.slideOutdownnav {
  -webkit-animation-name: slideOutdownnav;
  animation-name: slideOutdownnav
}

* {
  margin: 0;
  padding: 0;
}

.footer {
  width: 100%;
  height: 425px
}

.footercontainer {
  width: 1200px;
  height: 425px;
  margin: 0 auto
}

.footerwow {
  margin-top: 70px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}

.footerwowtext {
  width: 84px;
  margin-left: 18px;
  font-family: HappyZcool;
  font-weight: normal;
  font-size: 28px;
  color: #333333;
  line-height: 32px;
  text-align: left;
  font-style: normal;
}

.footnav span {
  width: 72px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  margin-right: 65px;
}

.telandaddress {

  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #777777;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}

.icp {
  width: 183px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #AAAAAA;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  cursor: pointer;

}

.box_1 {
  overflow: hidden;
  height: 88px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 44px;
  width: 1200px;
  margin-left: 360px;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-row:first-of-type {
  margin-top: 24px;
}

.label_1 {
  width: 44px;
  height: 47px;
}

.hd {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hddiv1 {
  display: flex;
  align-items: center;
}

.hddiv1 img {
  width: 31px;
  height: 31px;
  margin-right: 16px
}

.hdp {
  width: 104px;
  height: 37px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #333333;
  line-height: 37px;
  font-style: normal;
}

.hdd {
  width: 9px;
  height: 9px;
  background-color: #F8AE01;
  border-radius: 5px;
  margin-top: 18px;
  margin-left: 6px;
}

.hddesc {
  width: 432px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  font-style: normal;
  margin-top: 20px
}

.navspan {
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

.activets {
  font-weight: bold;
  transition-property: opacity;
}

.activets::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 60%;
  height: 100%;
  border-bottom: 4px solid rgb(248, 174, 1);
  transition: width 0.3s ease;
  border-radius: 2px;
}

.activets::before .sy {
  width: 50% !important;
}

.cursor {
  cursor: pointer;
}

.activenav {
  font-weight: 500;
  //color: black;
  transition-property: opacity;
}

.activenav::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 110%;
  height: 100%;
  border-bottom: 4px solid rgb(248, 174, 1);
  transition: width 0.3s ease;
  border-radius: 2px;
}

.activenav::before .sy {
  width: 50% !important;
}
.nav_menu.info{
  border-radius: unset;
  justify-content: unset;
  align-items: unset;
  /* align-items: center; */
  top: 0;
  margin-left: unset;
  position: unset;
}


</style>
