<template>
  <div style=" background-color: #F8F8F8;" @scroll="checkScroll" ref="scrollContainer">
    <!--背景图-->
    <div style="
height: 798px;
background: #F8AE01;position: relative">
      <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in homeData_banner" :key="index">
            <img alt="" :src="item.img" style="width: 100%;">
          </div>
        </div>
      </div>
<!--      <span class="wowapptext">哇呜说APP</span>-->
<!--      <span class="wowtext">陪伴孩子每一天</span>-->
      <div>
        <div id="iosdownload" @mouseenter="handleMouseOver(1)" class="downloadbg cursor " v-show="isHovered === 1">
          <img alt="" src="../assets/img/ios.png" style="width: 29px; height: 35px">
          <span>IOS下载</span>
        </div>
      </div>
      <div @mouseleave="handleMouseOut(1)" class="downloadbg cursor animate__animated animate__flipInX "
           style="z-index: 9999;" v-show="isHovered === 2" @click="">
        <img :src="basicinfo1.iosQrcode" style="width: 180px; height: 180px" alt="">
      </div>


      <div id="androiddownload" @mouseenter="handleMouseOver(2)" class="downloadbg cursor" style="left: 662px"
           v-show="isHoveredAndroid === 1">
        <img alt="" src="../assets/img/Android.png" style="width: 29px;height: 35px">
        <span>Android下载</span>
      </div>
      <div @mouseleave="handleMouseOut(2)" class="downloadbg cursor animate__animated animate__flipInX"
           v-show="isHoveredAndroid === 2" style="left: 662px">
        <img :src="basicinfo1.androidQrcode" style="width: 180px; height: 180px" alt="">
      </div>
    </div>

    <!--    wawa说理念-->
    <div style="width: 100%;background-color:#FCFAED;padding-bottom: 60px
;padding-top: 60px;">
      <div style="width: 1000px;text-align: center;margin: 0 auto 70px;" v-for="(item,index) in homeDataINfo1" :key="index">
        <div class="hd" style="">
          <div class="hddiv1">
            <img alt="" src="../assets/img/xiongzhua.png">
            <p class="hdp" style="width: unset !important;">{{ item.title||"" }}</p>
            <div class="hdd" style=""></div>
          </div>
          <div class="hddescabout">
            <img src="../assets/img/maohaokai.png" alt=""
                 style="margin-right: 10px;width: 40px;height: 33px;margin-top: 50px">
            <span class="lntext">{{ item.subtitle }}</span>
          </div>

          <div class="xg">
            <div></div>
            <span>{{ item.title ||""}}</span>
            <img src="../assets/img/maohaoguan.png">

          </div>
<!--          <p class="lndesc">-->
<!--            <span>始终如一的专注于一件事——以科技为笔，以技术研发为核心，绘制孩子兴趣与梦想的蓝图，打造独一无二的剧本创作天地</span>-->
<!--          </p>-->
          <swiper :options="swiperOption">
            <swiper-slide class="swiper-slide" v-for="(item1,index) in item.imgs" :key="index">
              <img alt="" :src="item1.img"  style="width: 1000px;">
            </swiper-slide>
          </swiper>
<!--          <div class="swiper-container" :id="`swiperTwo${index}`">-->
<!--            <div class="swiper-wrapper" >-->
<!--              <div class="swiper-slide"  style="width: 1000px" :class="`swiper-slide${index + 1}`"  v-for="(item1,index) in item.imgs" :key="index" >-->
<!--               -->
<!--              </div>-->

<!--            </div>-->

<!--            <div class="swiper-pagination"></div>&lt;!&ndash;分页器。如果放置在swiper外面，需要自定义样式。&ndash;&gt;-->
<!--          </div>-->
        </div>
      </div>
    </div>


    <!--    //-->
    <!--      应用场景-->
<!--    <div style="    display: flex;-->
<!--    flex-direction: column;-->
<!--    align-items: center;margin-top: 60px">-->
<!--      <div style="display: flex;align-items: center;">-->
<!--        <img alt="" src="../assets/img/xiongzhua.png" style="width: 31px;height: 31px;margin-right: 16px">-->
<!--        <p class="wowzz">{{ homeDataINfo2.title||''  }}</p>-->
<!--        <div style="width: 9px;height: 9px;background-color: #F8AE01;border-radius: 5px;margin-top: 18px;-->
<!--    margin-left: 6px;"></div>-->
<!--      </div>-->

<!--      <p class="yycjp" style="">{{ homeDataINfo2.subtitle||'' }}</p>-->
<!--    </div>-->
<!--    <div class="changjing">-->
<!--      <div class="contenttab">-->
<!--        <div class="swiper-container" id="swiperTwo">-->
<!--          <div class="swiper-wrapper" >-->
<!--            <div class="swiper-slide"  style="width: 1000px" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in homeDataINfo2.imgs" :key="index" >-->
<!--              <img alt="" :src="item.img"  style="width: 1000px;height: 550px;">-->
<!--            </div>-->

<!--          </div>-->

<!--          <div class="swiper-pagination"></div>&lt;!&ndash;分页器。如果放置在swiper外面，需要自定义样式。&ndash;&gt;-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
<!--    <div style="margin-top: 59px;width: 100%;height: 1px"></div>-->
  </div>
</template>
<script>
import tab1 from '../assets/img/tab1.png'
import tab2 from '../assets/img/tab2.png'
import tab3 from '../assets/img/tab3.png'
import tab4 from '../assets/img/tab4.png'
import tab5 from '../assets/img/tab5.png'
import tab6 from '../assets/img/tab6.png'
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css'
import {login, getInfo, gettab} from '@/api/user';


let swiper_Data;
export default {

  name: 'Home',
  data() {
    return {
      hoverIndex: 0,
      swiperInstance: null,
      zoom: 'scale(1)',
      isHovered: 1,
      isHoveredAndroid: 1,
      currentIndex: 0,
      images: [require('../assets/img/sleep.png'), require('../assets/img/man.png'), require('../assets/img/studychild.png'), require('../assets/img/pianochild.png')],
      texts: [
        {type: '早教类型1', desc: '针对剧本类型一号展开说说'},
        {type: '早教类型2', desc: '针对剧本类型二号展开说说'},
        {type: '早教类型3', desc: '针对剧本类型三号展开说说'},
        {type: '早教类型4', desc: '针对剧本类型四号展开说说'}
      ],
      activeName: 'first',
      tabs: [
        {name: '创作剧本',pic:tab1},
        {name: '亲子实时对话',pic:tab2},
        {name: '个性化剧本',pic:tab3},
        {name: '哇呜代写',pic:tab4},
        {name: '海量音色库',pic:tab5},
        {name: '哇呜托管',pic:tab6}
      ],
      activeTab: 0,
      basicinfo1:'',
      homeDataINfo1:[],
      homeDataINfo2:[],
      homeData_banner:'',
      swiperOption: {
        //自动轮播
        // autoplay: {
        //   delay: 1000
        // },
        //开启循环模式
        loop: true,
        autoplay: true,
        //开启鼠标滚轮控制Swiper切换
        mousewheel: true
      }
    }
  },
  components: {},
  methods: {

    getStyle(index) {
      if (this.hoverIndex === index) {
        return {
          width: '18px',
          height: '18px',
          borderRadius: '18px',
          background: '#FCFAED',
          border: '2px solid #F87801',
        };
      } else if (index === 0) {
        return {
          width: '8px',
          height: '8px',
          background: '#F8AE01',
          borderRadius: '8px',
        };
      } else {
        return {
          width: '8px',
          height: '8px',
          background: '#F8AE01',
          borderRadius: '8px',
        };
      }
      },
    checkScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      console.log(scrollContainer);
      if (scrollContainer.scrollTop === 0) {
        console.log('User is at the top of the page');
        // Do something when user reaches the top of the page
      }
    },
    selectTab(index) {
      this.activeTab = index;
    },
    prevImage() {
      // 更新当前图片索引，使其循环播放
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    nextImage() {
      // 当前索引减1，以实现向左移动的效果。加上images数组长度是为了防止索引变为负数时的影响
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    toggleDiv() {
      // 切换元素的hover状态
      // 如果当前状态为1，则更改为2；如果当前状态不是1，则更改为1
      this.isHovered = this.isHovered === 1 ? 2 : 1;
    },
    handleMouseOver(event) {
      if (event == 1) {
        this.isHovered = 2;
      } else {
        this.isHoveredAndroid = 2;

      }

    },
    handleMouseOut(event) {
      if (event == 1) {
        this.isHovered = 1;
        // 预留动画后摇
        // document.getElementById('iosdownload').classList.add('', 'animate__flipInX','animate__fast');
      } else {
        this.isHoveredAndroid = 1;
        // 预留动画后摇
        // document.getElementById('androiddownload').classList.add('', 'animate__flipInX','animate__fast');
      }

    },
    zoomIn() {
      this.zoom = 'scale(1.08)';
    },
    zoomOut() {
      this.zoom = 'scale(1)';
    },
    async handleLogin() {
      const res = await getInfo({username: 'test', password: 'test'});
      console.log(res);
    },
    // async getUserInfo() {
    //   const res = await getInfo();
    //   console.log(res);
    // }

  },
  mounted() {
    gettab(0).then(res => {
      console.log(res);
      this.$nextTick(()=>{
        let compdata= res.data.find(item => item.moduleName === "首页") || {};
        this.homeData_banner=compdata.banners
        // console.log(this.homeData_banner);
        this.homeDataINfo1=compdata.infos
        // swiper_Data=res.data[0].infos
        console.log('9999',this.homeDataINfo1);
        // this.homeDataINfo1.forEach((item, index) => {
        //   console.log('222',index);
        //  var ss=new Swiper(`#swiperTwo${index}`, {
        //     slidesPerView: 1,
        //     autoplay: true,
        //     loop: true,
        //     observer: true,
        //     observeParents: true,
        //   });
        // });
      })

    })



    // this.$nextTick(() => {
    //   console.log(this.homeDataINfo1);
    //
    //
    // });

    getInfo().then(res => {
    this.basicinfo1=res.data;

    })
    var swiperInstance = new Swiper('#swiperone', {
      // config...
      // autoplay:true,
      slidesPerView: 2,
      spaceBetween: 40,
      autoplay:true,
      loop: true,
      // pagination: {
      //   el: '.swiper-pagination_wow',
      //   clickable: true,
      //   bulletClass : 'my-bullet',
      //   bulletActiveClass: 'my-bullet-active',
      //   dynamicBullets: true,
      //   dynamicMainBullets: 1
      // },
      navigation: {
        nextEl: '.r_swiper',
        prevEl: '.l_swiper',
      },
    })
    var swiperInstance2 = new Swiper('#swiperthree', {
      // config...
      // autoplay:true,
      slidesPerView: 1,
      autoplay:true,
      loop: true,
      observer:true,
      observeParents: true,
    })
    this.$nextTick(() => {
      // 进入nexTick
      const body = document.getElementById("app");   // 获取滚动条的dom
      // 获取距离顶部的距离
      const scrollTop = body.scrollTop;
      // 获取可视区的高度
      const windowHeight = body.clientHeight;
      // 获取滚动条的总高度
      const scrollHeight = body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        this.show = true
      } else {
        this.show = false;
        // 滚动事件
        body.onscroll = () => {
          console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
          if (scrollTop + windowHeight >= scrollHeight) {
            // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
            this.show = true
          }
        }
      }
      console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
    });
    // this.handleLogin()
    // this.getUserInfo()



  }
}

</script>

<style scoped>

.changjing{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 1000px;
  /* text-align: center; */
  margin: 50px auto 0;
}



v:deep(.swiper-pagination-bullet-active){
background-color: #f0a500 !important;
}
.swiper-slide {
width: 345px;
}


.swiper-slide .swiper_item_p:first-of-type {
height: 33px;
font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 24px;
color: #F8AE01;
line-height: 33px;
text-align: center;
font-style: normal;
margin: 0 auto
}

.swiper-slide .swiper_item_p:nth-of-type(2) {
width: 112px;
height: 22px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #333333;
line-height: 22px;
text-align: left;
font-style: normal;
margin: 0 auto
}
.swiper-slide .swiper_item_p:nth-of-type(3) {
width: 125px;
height: 172px;
font-family: PingFangSC, PingFang SC;
font-weight: 600;
font-size: 123px;
color: #F8AE01;
line-height: 172px;
text-align: center;
font-style: normal;
margin:  0 auto;
opacity: 0.1;
}
.swiper-container {
width: 1000px;
margin-top: 50px;
}
.swiper_control img{
width: 42px;
height: 42px;
}
.swiper_control{
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
margin-top: -100px;
z-index: 999;
}
.swiper_control div:nth-of-type(2){
margin-left: 50px;
}
.swiper_control div:nth-of-type(3){

}


.wowapptext {
width: 311px;
height: 60px;
font-family: HappyZcool, HappyZcool;
font-weight: normal;
font-size: 60px;
color: #FFFFFF;
line-height: 68px;
text-align: left;
font-style: normal;
position: absolute;
top: 281px;
left: 425px;
}

.wowtext {
width: 404px;
height: 50px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 36px;
color: #FFFFFF;
line-height: 50px;
letter-spacing: 21px;
text-align: left;
font-style: normal;
position: absolute;
left: 425px;
top: 371px;
}

.downloadbg {
width: 180px;
height: 180px;
background: #FFFFFF;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: absolute;
top: 491px;
left: 425px;
  z-index: 99;
}

.downloadbg img {
margin-bottom: 14px;
}

.content {
height: 667px;
background-color: #fff;
margin-top: 60px;
position: relative;
}

.bookk {
width: 847px;
height: 397px;
margin: 0 auto;
display: flex;
justify-content: space-between;
}

.bookkhandmade {
width: 260px;
height: 60px;
background: #F8AE01;
border-radius: 10px;
text-align: center;
position: absolute;
bottom: 122px;
left: 76px;
z-index: 2
}

.handmadetexe {
width: 96px;
height: 33px;
font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 24px;
color: #FFFFFF;
line-height: 60px;
text-align: left;
font-style: normal;
}

.bookexplain {
width: 360px;
height: 130px;
background: #F8AE01;
border-radius: 20px;
opacity: 0.2;
text-align: center;
position: absolute;
bottom: 22px;
left: 26px
}

.bookexplaintext {
width: 306px;
height: 48px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 17px;
color: #333333;
text-align: left;
font-style: normal;
position: absolute;
bottom: 48px;
left: 53px
}

.contentyellow {
width: 1001px;
height: 187px;
background: #F8AE01;
box-shadow: 0px 8px 29px 0px rgba(248, 174, 1, 0.62), inset 0px 7px 15px 0px rgba(255, 255, 255, 0.5);
border-radius: 20px;
position: absolute;
bottom: 61px;
left: 459px;
z-index: 1;
}

.lunbo {
width: 1000px;
height: 346px;
//border: 1px solid #F8AE01;
position: relative;
margin: 50px auto 60px;

}

.firschild {
width: 150px;
height: 200px;
border-radius: 10px;
border: 5px solid #F8AE01;
background-color: #F8AE01;
position: absolute;
bottom: 30px;
left: 0px;
display: flex;
align-items: center;
justify-content: space-around;

}

.secchild {
width: 300px;
height: 260px;
background: #F8AE01;
border-radius: 10px;
position: absolute;
bottom: 0px;
left: 187px;
}

.secchild img {
width: 261px;
height: 232px;
position: absolute;
bottom: 114px;
left: 20px
}

.sectype {

height: 28px;
font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 20px;
color: #FFFFFF;
line-height: 28px;
font-style: normal;
position: absolute;
bottom: 62px;
left: 20px;
}

.secdesc {
width: 192px;
height: 22px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
line-height: 22px;
font-style: normal;
position: absolute;
bottom: 30px;
left: 20px;
}

.lbutton {
width: 54px;
height: 54px;
position: absolute;
left: -95px;
bottom: 103px;
}

.rbutton {
width: 54px;
height: 54px;
position: absolute;
right: -95px;
bottom: 103px;
}

.xiebutton {
width: 43px;
height: 42px;
position: absolute;
bottom: 56px;
right: 28px;
}

.thirdchild img {
width: 261px;
height: 232px;
position: absolute;
bottom: 114px;
left: 20px
}

.fourchild {
width: 150px;
height: 200px;
border-radius: 10px;
border: 5px solid #F8AE01;
position: absolute;
bottom: 30px;
right: 0px;
background-color: #F8AE01;
display: flex;
align-items: center;
justify-content: space-around;
}

.thirdchild {
width: 300px;
height: 260px;
background: #F8AE01;
border-radius: 10px;
position: absolute;
bottom: 0px;
right: 187px;
}

.tabs {
  display: flex;
  /* width: 1000px; */
  flex-direction: column;
  width: 150px;
  margin-right: 16px;

}

.tab {
cursor: pointer;
transition: background-color 0.3s;
  width: 150px;
  height: 40px;
line-height: 45px;
text-align: center;
transition: border-top-right-radius 0.3s, border-top-left-radius 0.3s;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.tab:not(.activetab) {
color: #666;
background-color: #fff;
}

.tab:first-of-type {
}

.tab:not(.activetab):not(:nth-of-type(5)) {
  position: relative;
}


.tab:nth-of-type(5) {
}

.tab.activetab {
  background-color: #f0a500;
  color: white;
}

.contenttab {
  width: 834px;
}

.actab {
  width: 1000px;
  height: 550px;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: 0px 0px 20px 20px;
  bottom: 0px;
  left: 0px;
  opacity: 0.456789;
}

.actabp {


  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #FFFFFF;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  bottom: 39px;
  left: 43px;
  position: absolute;
}

.hidden {
  display: none;
}

.imgzoom {
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
}

.yycjp {

  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  font-style: normal;
  margin-top: 20px
}

.xg {
  width: unset !important;
  display: flex;
  align-items: center;
  margin-left: 693px;
  margin-top: 24px;
}

.xg div {
  width: 61px;
  height: 1px;
  background-color: #F8AE01;
  margin-right: 14px
}

.xg span {
  height: 29px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 21px;
  color: #F8AE01;
  line-height: 29px;
  text-align: left;
  font-style: normal;
  margin-right: 14px;
}

.xg img {
  margin-right: 12px;
  width: 40px;
  height: 33px;
  margin-top: 15px
}

.lndesc {
  width: 1000px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #5E3B15;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  margin-top: 50px;
  margin-bottom: 50px;
}

.lntext {
  width: 802px;
  height: 26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 19px;
  color: #5E3B15;
  line-height: 26px;
  text-align: left;
  font-style: normal;
}

.hddescabout {
  width: unset !important;
  margin-right: 55px
}

.wowzz {

  height: 37px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #333333;
  line-height: 37px;
  font-style: normal;
}

.tabcontainer {
  position: relative;
}

.tabcontainer span {
  position: absolute;
}

.tabcontainer .tabtitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  top: 209px;
  left: 104px;
}

.tabcontainer .tabcontent {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  top: 253px;
  left: 104px;;
}

.tabcontainer .tabcontent2 {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  bottom: 249px;
  left: 104px;

}

</style>
<style type="text/css">
.my-bullet{
  display: inline-block;
  border-radius: 100%;
  opacity: .2;
  width: 8px;
  height: 8px;
  background: #F8AE01;
}
.my-bullet-active{
  width: 18px;
  height: 18px;
  background: #FCFAED;
  border: 2px solid #F87801;
  opacity: 1;
}
.swiper-pagination_wow{
  display: flex;
  margin-top: -31px;
  justify-content: space-evenly;
}

#swiperTwo{
  margin-top: unset;
}
</style>
<style type="text/css">
.swiper-container{
  //--swiper-theme-color:  #FFFFFF;
  --swiper-pagination-color: #FFFFFF;/* 两种都可以 */
}
.swiper-button-next, .swiper-button-prev{
  color: #FFFFFF;
}
</style>