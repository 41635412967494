<template>
    <div>
      <div style="
    height: 798px;
    background: #F8AE01;position: relative;overflow: hidden">
          <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
            <div class="swiper-wrapper">
              <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in homeData_banner" :key="index">
                <img alt="" :src="item.img" style="width: 100%;">
              </div>
            </div>
          </div>
        </div>
      <div style="height: 60px;margin-top: 19px;z-index:999" class="nav_top">
        <div style="width: 1200px;height: 60px;margin: 0 auto">
          <span>您的位置:</span>&nbsp;&nbsp;
          <span @click="goPro" style="cursor: pointer">产品介绍></span>&nbsp;&nbsp;
          <span style="color: #F8AE01">哇呜说电子说明书</span>&nbsp;&nbsp;
        </div>
    
      </div>
      <div style="width: 1000px;text-align: center;margin: 0 auto">
        <div class="file-list">
          <div v-for="(item, index) in info" :key="index" class="file-item">
            <div class="file-info">
              <div class="file-left">
                <div class="file-icon">
                  <i class="doc-icon">📄</i>
                </div>
                <div class="file-details">
                  <div class="file-title">{{ item.title }}</div>
                  <div class="file-meta">电子说明书文档</div>
                </div>
              </div>
              <a :href="item.imgs[0].img" 
                 download 
                 class="download-btn"
                 :class="{'is-loading': loadingStates[index]}"
                 @click.prevent="handleDownload(item.imgs[0].img, item.title, index)">
                <span class="download-text" v-if="!loadingStates[index]">下载文档</span>
                <div class="loading-spinner" v-else></div>
                <i class="download-icon" v-if="!loadingStates[index]">↓</i>
              </a>
            </div>
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <script>
    import {gettab} from '@/api/user';
    export default {
      data() {
        return {
          helpVideo:'',
          homeData_banner:[],
          info:[],
          loadingStates: {}
        }
      },
      methods: {
        goPro() {
          this.$router.push({
            path: '/introduction'
          })
        },
        async handleDownload(url, filename, index) {
          if (this.loadingStates[index]) return;
          
          this.$set(this.loadingStates, index, true);
          try {
            const response = await fetch(url);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${filename}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
          } catch (error) {
            console.error('Download failed:', error);
            alert('下载失败，请稍后重试');
          } finally {
            this.$set(this.loadingStates, index, false);
          }
        }
      },
      mounted() {
        console.log(this.$route.query)
        gettab(0).then(res => {
          console.log(res);
          this.$nextTick(()=>{
            let compdata = res.data.find(item => item.moduleName === "电子说明书") || {};
            console.log(compdata);
            this.homeData_banner = compdata.banners;
            this.info=compdata.infos;
          })
        })
      }
    }
    
    </script>
    <style scoped>
    .file-list {
      width: 1000px;
      padding: 30px;
      background: #fff;
      border-radius: 16px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
      margin-top: 40px;
    }

    .file-item {
      margin-bottom: 24px;
    }

    .file-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      background: #fff;
      border-radius: 12px;
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid rgba(242, 167, 3, 0.1);
    }

    .file-info:hover {
      background: rgba(242, 167, 3, 0.02);
      transform: translateX(5px);
      border: 1px solid rgba(242, 167, 3, 0.2);
      box-shadow: 0 4px 20px rgba(242, 167, 3, 0.1);
    }

    .file-left {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .file-icon {
      width: 48px;
      height: 48px;
      background: rgba(242, 167, 3, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }

    .file-details {
      text-align: left;
    }

    .file-title {
      font-size: 18px;
      color: #333;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .file-meta {
      font-size: 14px;
      color: #666;
      opacity: 0.8;
    }

    .download-btn {
      display: flex;
      align-items: center;
      background: linear-gradient(135deg, #f2a703 0%, #f8ae01 100%);
      color: white;
      padding: 12px 28px;
      border-radius: 10px;
      text-decoration: none;
      transition: all 0.3s ease;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    .download-btn:hover {
      background: linear-gradient(135deg, #e09602 0%, #f2a703 100%);
      transform: translateY(-2px);
      box-shadow: 0 8px 20px rgba(242, 167, 3, 0.2);
    }

    .download-text {
      margin-right: 10px;
      font-size: 15px;
    }

    .download-icon {
      font-size: 18px;
      font-style: normal;
      transition: transform 0.3s ease;
    }

    .download-btn:hover .download-icon {
      transform: translateY(2px);
    }

    .divider {
      height: 1px;
      background: linear-gradient(to right, 
        rgba(242, 167, 3, 0.05), 
        rgba(242, 167, 3, 0.15), 
        rgba(242, 167, 3, 0.05));
      margin-top: 24px;
    }

    .file-item:last-child {
      margin-bottom: 0;
    }

    .file-item:last-child .divider {
      display: none;
    }

    .download-btn.is-loading {
      background: #f2a703;
      cursor: wait;
      pointer-events: none;
    }

    .loading-spinner {
      width: 20px;
      height: 20px;
      border: 2px solid #ffffff;
      border-top-color: transparent;
      border-radius: 50%;
      animation: spinner 0.8s linear infinite;
      margin: 0 10px;
    }

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }

    .download-btn.is-loading:hover {
      transform: none;
      box-shadow: none;
    }
    </style>