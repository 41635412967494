<template>
<div>
  <div style="
height: 798px;
background: #F8AE01;position: relative;overflow: hidden">
      <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in homeData_banner" :key="index">
            <img alt="" :src="item.img" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
  <div style="height: 60px;margin-top: 19px;z-index:999" class="nav_top">
    <div style="width: 1200px;height: 60px;margin: 0 auto;display: flex;justify-content: space-between;align-items: center">
      <div>
        <span>您的位置:</span>&nbsp;&nbsp;
        <span @click="goPro" style="cursor: pointer">产品介绍></span>&nbsp;&nbsp;
        <span style="color: #F8AE01">哇呜说使用教程</span>&nbsp;&nbsp;
      </div>
      <button 
        @click="goToManual" 
        style="
          background-color: #f2a703;
          color: white;
          border: none;
          padding: 8px 20px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: opacity 0.2s;
        "
        onmouseover="this.style.opacity='0.8'"
        onmouseout="this.style.opacity='1'"
      >
        哇呜电子说明书下载
      </button>
    </div>
  </div>
  <div style="width: 1000px;text-align: center;margin: 0 auto">
    <div class="hd" style="" v-for="(item,index) in filteredHelpVideo" :key="index">
      <div class="hddiv1" style="margin-top: 60px">
        <img alt="" src="../assets/img/xiongzhua.png">
        <p class="hdp" style="width: unset !important;">{{ item.title }}</p>
        <div class="hdd" style=""></div>
      </div>

      <div style="display: flex;margin-top: 36px;flex-wrap: wrap;width: 1000px;justify-content: space-between;">
        <div class="lndesc" style="margin-top: 30px" v-for="(subtitle, subtitleIndex) in item.subtitles" :key="subtitleIndex">
          <div style="margin-bottom: 20px">{{subtitle}}</div>
          <video controls 
                 class="about_img" 
                 style="width: 490px;height: 279px;"
                 :src="item.imgs[subtitleIndex].img"
                 @play="handleVideoPlay"
                 alt="">
          </video>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {gettab} from '@/api/user';
export default {
  data() {
    return {
      helpVideo:'',
      homeData_banner:[],
      VIDEO_FILTERS: {
        'hb1': {
          title: '硬件连接',
          subtitle: '哇呜伙伴连接方式一'
        },
        'hb2': {
          title: '硬件连接',
          subtitle: '哇呜伙伴连接方式二'
        },
        'ht1': {
          title: '硬件连接',
          subtitle: '哇呜话筒连接方式一'
        },
        'ht2': {
          title: '硬件连接',
          subtitle: '哇呜话筒连接方式二'
        },
        'app1': {
          title: '哇呜说APP使用教程',
          subtitle: ''
        }
      }
    }
  },
  computed: {
    filteredHelpVideo() {
      const filterType = this.$route.query.v;
      
      if (!filterType) {
        return this.helpVideo;
      }

      const filterConfig = this.VIDEO_FILTERS[filterType];
      if (!filterConfig) {
        return this.helpVideo;
      }
      if (filterType === 'app1') {
        return this.helpVideo
          .filter(item => item.title === filterConfig.title)
      }
      const { title, subtitle } = filterConfig;
      
      const filteredItems = this.helpVideo
        .filter(item => item.title === title)
        .map(item => ({
          ...item,
          subtitles: item.subtitles.filter(sub => sub === subtitle),
          imgs: item.imgs.filter((_, index) => item.subtitles[index] === subtitle)
        }))
        .filter(item => item.subtitles.length > 0);

      return filteredItems.length > 0 ? filteredItems : this.helpVideo;
    }
  },
  methods: {
    goPro() {
      this.$router.push({
        path: '/introduction'
      })
    },
    goToManual() {
      this.$router.push({
        path: '/help/sms'
      })
    },
    handleVideoPlay(){
      // 获取所有视频元素
      const videos = document.getElementsByTagName('video')
      // 遍历所有视频
      Array.from(videos).forEach(video => {
        // 如果不是当前播放的视频，就暂停它
        if (video !== event.target) {
          video.pause()
        }
      })
    }
  },
  mounted() {
    console.log(this.$route.query)
    gettab(0).then(res => {
      console.log(res);
      this.$nextTick(()=>{
        let compdata = res.data.find(item => item.moduleName === "帮助视频") || {};
        console.log(compdata);
        this.homeData_banner = compdata.banners;
        
        // 处理相同title的数据
        const mergedData = {};
        compdata.infos.forEach(item => {
          if (!mergedData[item.title]) {
            mergedData[item.title] = {
              ...item,
              subtitles: [item.subtitle],
              imgs: [...item.imgs]
            };
          } else {
            // 如果已存在相同title，合并subtitle和imgs
            mergedData[item.title].subtitles.push(item.subtitle);
            mergedData[item.title].imgs.push(...item.imgs);
          }
        });
        
        // 转换回数组形式
        this.helpVideo = Object.values(mergedData);
        console.log('Merged help video data:', this.helpVideo);
      })
    })
  }
}

</script>
<style scoped>

</style>