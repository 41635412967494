import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import home from "/src/components/home.vue";
import introduction from "/src/components/Introduction.vue";
import information from "/src/components/information.vue";
import about from "/src/components/about.vue";
import infodetails from "/src/components/infoDetail.vue";
import appdetails from "/src/components/int_app.vue";
import harddetails from "/src/components/harddetails.vue";
import hardbuy from "/src/components/hardbuy.vue";
import video from "/src/components/video.vue";
import sms from "/src/components/sms.vue";
// 使用 Router
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: "/",
            component: home,
            name: "home"
        },
        {
            path: "/introduction",
            component: introduction,
            name: "introduction"
        },
        {
            path: "/information",
            component: information,
            name: "information"
        },
        {
            path: "/information/infodetails",
            component: infodetails,
            name: "infodetails"
        },
        {
            path: "/introduction/app",
            component: appdetails,
            name: "appdetails"
        },
        {
            path: "/introduction/hard",
            component: harddetails,
            name: "harddetails"
        },
        {
            path: "/introduction/hard/buy",
            component: hardbuy,
            name: "hardbuy"
        },
        {
            path: "/help/sms",
            component: sms,
            name: "sms"
        },
        {
            path: "/about",
            component: about,
            name: "about"
        },
        {
            path: "/help/video",
            component: video,
            name: "video"
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // 每次路由跳转时滚动到顶部
        return { x: 0, y: 0 };
    }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

// 添加全局导航守卫
router.beforeEach((to, from, next) => {
    // 检查URL中是否包含manual
    if (window.location.href.includes('manual')) {
        // 检查是否为移动设备
        const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        // 根据设备类型跳转到不同的URL
        if (isMobile) {
            window.location.href = 'https://h5.wow-tech.com/#/help/sms';
        } else {
            window.location.href = 'https://www.wow-tech.com/#/help/sms';
        }
        return;
    }
    next();
});

export default router;
