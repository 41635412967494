import Vue from 'vue'
import App from './App.vue'
import router from "./router/index.js";
// import './aliDpr.js'
import 'animate.css';
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import 'swiper/css/swiper.css'
import vueSwiper from 'vue-awesome-swiper'  //引入vue-awesome-swiper
// 获取浏览器窗口的宽度，以便在不同设备上适应页面布局
const width = window.innerWidth || document.documentElement.clientWidth;
Vue.use(vueSwiper);
// 计算页面缩放比例，基于默认的1920宽度进行计算
var scale = width / 1920;

// 动态设置HTML文档的缩放比例，实现页面的自适应布局
document.documentElement.style.zoom = scale;

new Vue({
  render: h => h(App)
  ,router

}).$mount('#app')

